.share-icon {
    width: 20px;
    cursor: pointer;
    margin-left: 5px;
}

@media screen and (max-width: 600px) {
    .share-icon {
        width: 30px;
        cursor: pointer;
        margin-left: 5px;
    }
}

.container-fields {
    min-height: 200px;
}

.varieties-container {
    min-height: 100px;
}

/*
.container-add-to-list {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.qty-add-to-list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.qty-add-to-list>svg {
    cursor: pointer;
}
*/
.product-price {
    display: flex;
    align-items: center;
}

.container-add-to-list {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-add-to-list > * {
    margin: 5px;
}

.container-add-to-list > svg {
    cursor: pointer;
}



.markers-container {
    display: flex;
    display: -webkit-flex;
    /* max-height: 90px; */
    max-height: 30px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -ms-flexbox;
}

.markers-container > .marker[type="plus"] {
    display: none !important;
}

.markers-container div:hover {
    cursor: pointer;
}

@media screen and (max-width: 720px) {
    .container-add-to-list {
        position: initial;
        margin-bottom: 10px;
    }

    .container-fields {
        margin-top: 10px;
        min-height: 0px;
    }

    .varieties-container {
        min-height: 0px;
    }

    .product-price {
        justify-content: center
    }
}


